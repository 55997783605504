import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/papaya1.jpg";
import Image2 from "./images/papaya2.webp";
import Image3 from "./images/papaya3.jpg";
import Image4 from "./images/papaya4.jpg";
import Image5 from "./images/papaya5.jpg";
import Image6 from "./images/papaya6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const papayaBenefits = [
  {
    text: "Rich in Nutrients",
    details:
      "Packed with vitamins A, C, folate, and potassium, supporting overall health.",
  },
  {
    text: "Boosts Immunity",
    details:
      "High in vitamin C, strengthening your immune system and fighting free radicals.",
  },
  {
    text: "Supports Digestion",
    details:
      "Contains papain, an enzyme that aids in digestion and reduces bloating.",
  },
  {
    text: "Promotes Skin Health",
    details:
      "Antioxidants like beta-carotene and vitamin C help maintain youthful, glowing skin.",
  },
  {
    text: "Anti-inflammatory",
    details:
      "Helps reduce inflammation, thanks to its high antioxidant content.",
  },
  {
    text: "Improves Eye Health",
    details:
      "Vitamin A supports vision and may reduce the risk of age-related eye conditions.",
  },
];

const PapayaPage = () => (
  <Layout>
    <Seo title="Papaya" />
    <Breadcrumbs title="Papaya" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 200px; height: 200px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 200px; height: 200px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .fixed-size { width: 100%; height: auto; }
                .narrow-paragraph { padding: 0 15px; }
                .custom-table, .custom-table th, .custom-table td { font-size: 14px; }
                .farm-description img { width: 100%; height: auto; margin-right: 10px; }
                .margin-bottom { margin-bottom: 30px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Papaya field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Papaya field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Papayas, often referred to as the “fruit of the angels,” are
                    not only delicious but also packed with a wealth of health
                    benefits. Native to Central America and the tropics, papayas
                    have gained popularity worldwide for their sweet, tropical
                    flavor and impressive nutritional profile. When grown
                    organically, papayas offer even more advantages, as they are
                    free from synthetic pesticides and chemicals, making them a
                    cleaner and healthier choice. Let’s explore why organic
                    papayas are a fantastic addition to your diet.
                  </p>
                  <p>
                    Organic papayas are cultivated without the use of harmful
                    pesticides or synthetic fertilizers, ensuring they are grown
                    in a way that supports both your health and the environment.
                    By choosing organic, you are supporting sustainable farming
                    practices that protect the soil, water, and surrounding
                    ecosystems. Organic papayas also tend to have a more vibrant
                    flavor, as they are grown in nutrient-rich, healthy soil
                    that encourages the development of their natural sweetness
                    and richness.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Amount (per 1 cup, 145g)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>59 kcal</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>15g</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>1g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>3g</td>
                  </tr>
                  <tr>
                    <td>Sugar</td>
                    <td>9g</td>
                  </tr>
                  <tr>
                    <td>Vitamin A</td>
                    <td>1400 IU (28% of DV)</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>88mg (147% of DV)</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>360mg (10% of DV)</td>
                  </tr>
                  <tr>
                    <td>Folate</td>
                    <td>53mcg (13% of DV)</td>
                  </tr>
                  <tr>
                    <td>Magnesium</td>
                    <td>30mg (7% of DV)</td>
                  </tr>
                  <tr>
                    <td>Calcium</td>
                    <td>20mg (2% of DV)</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>0.5mg (3% of DV)</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Papayas</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {papayaBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PapayaPage;
